@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .12);
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, .05);
  }

  .scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .06);
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, .05);
  }
}